<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <!-- <slot name="text-title"></slot> -->
        <span>Download Options</span>
      </div>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <p>AudaNet will overwrite quote. Are you sure you want to continue?</p><br>
        <p><strong>Download</strong> - Will NOT overwrite if calculation ID is the same</p>
        <p><strong>Download &amp; Overwrite</strong> - Will overwrite even if calculation ID is the same</p>
        <p><strong>Cancel</strong> - Cancel download quote operation</p>
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="download()"
        data-tooltip="Download">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-download" />
        </span>
        <span>Download</span>
      </button>
      <button class="button is-success tooltip"
        @click="overwrite()"
        data-tooltip="Download &amp; Overwrite">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-download" />
        </span>
        <span>Download &amp; Overwrite</span>
      </button>
      <button class="button tooltip"
        @click="cancel()"
        data-tooltip="Cancel"
        :disabled="cancelDisabled">
        <span>Cancel</span>
      </button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'

export default {
  name: 'AudanetDownloadQuoteModal',
  components: {
    BaseModalEx
  },
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-download'
    },
    copyDisabled: {
      type: Boolean,
      default: false
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      innerValue: null
    }
  },
  validations: {},
  computed: {},
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {
    this.innerValue = this.value
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    download() {
      this.$emit('download', false)
    },
    overwrite() {
      this.$emit('download', true)
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
